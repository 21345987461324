import style from "modules/SearchCandidate/pageHeader/page-header.module.scss";
import React from "react";
import { ReactComponent as Add } from "static/icons/PlusAdd.svg";
import { ReactComponent as SendVacancy } from "static/icons/IconsendIcon.svg";
import useSendVacancy from "../components/SendVacancy/hooks/useSendVacancy";
import useShortList from "../components/ShortListDrawer/useShortList";
import _get from "lodash/get";
import { queryCache } from "react-query";
import usePoolStore from "../hooks/zustandPool";
import { QUERY } from "config";

const ProcessMenu = ({ selectionBucket, mode }) => {
	const isSelect =
		mode === QUERY || selectionBucket.state.selectedRows.length > 0;

	const { setIsOpen } = useSendVacancy();
	const { setIsOpen: openDrawerShortList } = useShortList();

	const { select_data } = usePoolStore();

	const mode_ = _get(select_data, "mode");
	const selectedCandidates = _get(
		select_data,
		"selected_candidates_for_ShortList"
	);
	const shortListIDs = _get(select_data, "shortList");

	let IdsShortList = [];

	if (mode_ === "query") {
		IdsShortList = shortListIDs;
	} else {
		IdsShortList = selectedCandidates;
	}

	// const total_item = selectionBucket.state.selectedRows.length;

	const { total_item } = select_data;

	return (
		<>
			<div className={style.processContainer}>
				<button
					disabled={IdsShortList === 0}
					onClick={() => {
						queryCache.invalidateQueries("getVacanciesShortList");
						openDrawerShortList(true);
					}}
				>
					<Add /> Add to vacancy{" "}
					{IdsShortList.length > 0
						? "(" + IdsShortList.slice(0, 24).length + ")"
						: ""}
				</button>
				<button
					onClick={() => {
						if (isSelect) return setIsOpen(true);
						return;
					}}
					disabled={total_item === 0}
				>
					<SendVacancy /> Send vacancy{" "}
					{total_item > 0 ? "(" + total_item + ")" : ""}
				</button>
			</div>
		</>
	);
};

export default ProcessMenu;
