import cx from "classnames";
import React, { forwardRef } from "react";
import { ReactComponent as Icon } from "static/icons/search-icon-input.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-icon-black.svg";
import styles from "./search-input.module.scss";
import { IconButton } from "common/IconButton";

const SearchInput = (
	{
		className,
		value,
		onChange,
		onDelete,
		variant,
		style,
		placeholder = "Search",
		inputClassName,
		icon = null,
		disabled,
		iconPosition = "right",
		onFocus,
		searchIconClassName,
		showDelete,
		...rest
	},
	ref
) => {
	const handleKeyPress = event => {
		if (event.key === "Enter" && ref) {
			ref.current?.blur();
		}
	};
	return (
		<div
			className={cx(styles.root, className, {
				[styles.borderless]: variant === "borderless",
				[styles.fixed_width]: variant === "fixed_width"
			})}
			style={style}
			data-onboarding-step={rest["data-onboarding-step"]}
		>
			{iconPosition === "left" ? (
				icon ? (
					icon
				) : (
					<Icon className={cx(styles.iconLeft, searchIconClassName)} />
				)
			) : null}
			<input
				value={value}
				onChange={onChange}
				onKeyPress={handleKeyPress}
				className={cx(styles.input, inputClassName, {
					[styles.paddingLeft]: iconPosition === "left"
				})}
				placeholder={placeholder}
				ref={ref}
				disabled={disabled}
				onFocus={onFocus}
				{...rest}
			/>
			{iconPosition === "right" ? (
				icon ? (
					icon
				) : (
					<Icon className={styles.iconRight} />
				)
			) : null}
			{showDelete && value && (
				<IconButton onClick={onDelete} className={styles.closeIcon}>
					<CloseIcon width={10} height={10} />
				</IconButton>
			)}
		</div>
	);
};

export default forwardRef(SearchInput);
