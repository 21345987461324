import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { browserHistory } from "react-router";
import { readNotifications } from "../actions";
import NotificationRow from "./common/NotificationRowClient";
import { Scrollbars } from "react-custom-scrollbars";
import {
	IS_URL_REGEX,
	LATEST,
	SEND_BULK_PROCESS_COMPLETED_NOTIFICATION,
	SEND_EXPORTED_DATA_COMPLETED_NOTIFICATION,
	SYSTEM_NOTIFICATIONS
} from "../../../config";
import { ReactComponent as ChevronDown } from "static/icons/chevron-down.svg";
import { ReactComponent as ChevronUp } from "static/icons/chevron-up.svg";
import styles from "./style.module.scss";
import useGetSystemNotifications from "../hooks/useGetSystemNotifications";
import { ReactComponent as AnimatedLoaderIcon } from "static/icons/loader-animated.svg";
import useInfiniteScroll from "hooks/useInfiniteScroll";
import useGetFiles from "common/AddCandidateDrawer/hooks/useGetFiles";
import { PARCEL_DETAILS_ID } from "common/AddCandidateDrawer/utils/constants";

const SystemNotifications = ({ view, setLatestCount, setUnreadCount }) => {
	const [opened, setOpened] = useState(true);

	const reduceData = data => {
		if (!data) return [];

		return data.reduce((acc, val) => [...acc, ...val.data], []);
	};

	const observerTarget = useRef(null);

	const {
		data,
		fetchMore,
		canFetchMore,
		isFetchingMore
	} = useGetSystemNotifications(
		{
			offset: 0,
			tag: SYSTEM_NOTIFICATIONS,
			field: view
		},
		{
			getFetchMore: (lastGroup, allData) => {
				if (lastGroup.pagination.total === reduceData(allData).length)
					return false;
				return lastGroup.pagination.offset + 10;
			}
		}
	);

	const reducedData = useMemo(() => reduceData(data), [data]);

	let systemNotificationCount;
	if (view === LATEST)
		systemNotificationCount = data?.[0].counts?.system?.total_latest;
	else systemNotificationCount = data?.[0].counts?.system?.total_unread;

	useInfiniteScroll({
		observerTarget,
		callback: () => {
			if (!isFetchingMore) {
				fetchMore();
			}
		}
	});

	const dispatch = useDispatch();

	const readNotification = notification => {
		notification = JSON.parse(JSON.stringify(notification));
		dispatch(readNotifications(notification._id));
		if (IS_URL_REGEX.test(notification.message.url)) {
			window.open(notification.message.url);
		} else browserHistory.push(notification.message.url);
	};

	const {
		setDrawerActiveBody,
		setCurrentParcelId,
		setDisplayCreateCandidateDrawer
	} = useGetFiles();

	const renderData = notifications => {
		if (!notifications) return;

		if (notifications.length) {
			return notifications.map((notification, index) => {
				return (
					<React.Fragment key={notification._id}>
						{!!index && <div className={styles.notif_item_divider} />}
						<NotificationRow
							onRead={notif => {
								if (notif.type === SEND_BULK_PROCESS_COMPLETED_NOTIFICATION) {
									setCurrentParcelId(notif.message.url.split("=")[1]);
									setDrawerActiveBody(PARCEL_DETAILS_ID);
									setDisplayCreateCandidateDrawer(true);
									notif = JSON.parse(JSON.stringify(notif));
									dispatch(readNotifications(notif._id));
									browserHistory.push(notif.message.url.split("?")[0]);
								} else if (
									notif.type === SEND_EXPORTED_DATA_COMPLETED_NOTIFICATION
								) {
									window.location.href = notif.message.excel_link.replace(
										"http",
										"https"
									);
								} else readNotification(notif);
							}}
							notification={notification}
						/>
					</React.Fragment>
				);
			});
		}
	};

	useEffect(() => {
		const latestCount =
			data?.[0].counts?.system?.total_latest +
			data?.[0].counts?.mission?.total_latest +
			data?.[0].counts?.other?.total_latest;
		const unreadCount =
			data?.[0].counts?.system?.total_unread +
			data?.[0].counts?.mission?.total_unread +
			data?.[0].counts?.other?.total_unread;
		setLatestCount(latestCount);
		setUnreadCount(unreadCount);
	}, [data]);

	return (
		<div className={styles.collapsible}>
			<div className={styles.collapsible_header}>
				<div className={styles.title}>
					<span>System notifications</span>
					{!!reducedData?.length && (
						<div className={styles.badge}>{systemNotificationCount}</div>
					)}
				</div>
				<button
					className={styles.trigger}
					disabled={reducedData?.length === 0}
					onClick={() => setOpened(prev => !prev)}
				>
					{reducedData?.length !== 0 && opened ? (
						<>
							<span>Close</span>
							<ChevronUp />
						</>
					) : (
						<>
							<span>Open</span>
							<ChevronDown />
						</>
					)}
				</button>
			</div>
			{reducedData?.length !== 0 && (
				<>
					{opened && <div className={styles.collapsible_divider} />}
					<div
						className={styles.collapsible_content}
						style={{ height: opened ? 335 : 0 }}
					>
						<Scrollbars
							autoHide
							autoHideTimeout={5000}
							autoHideDuration={200}
							autoHeight
							autoHeightMax={335}
						>
							{renderData(reducedData)}
							{canFetchMore && (
								<div
									ref={observerTarget}
									className={styles.infiniteLoadingIndicator}
								>
									{isFetchingMore && <AnimatedLoaderIcon width={20} />}
								</div>
							)}
						</Scrollbars>
					</div>
				</>
			)}
		</div>
	);
};

export default SystemNotifications;
