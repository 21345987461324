import axios from "axios";
import _get from "lodash/get";
import _isObject from "lodash/isObject";
import { stopSubmit } from "redux-form";
import { browserHistory } from "react-router";
import {
	API_URL,
	MIME_JSON,
	STATUS_ACTIVATED,
	IDENTITY,
	CLIENT,
	COOKIE_TOKEN
} from "./index";
import store from "../store";
import toaster from "common/Toaster";
import { receiveLogout } from "../modules/user/actions/authActions";
import * as endpoints from "config/api-endpoints";
import { axiosPropsSelectors } from "../modules/app/selectors";
import { getCookie, getLocalUser } from "./helpers";
import { logout } from "lib/api-client";

// http codes
const HTTP_POST = "post";
const HTTP_GET = "get";
const ACTION_CREATOR = "ACTION_CREATOR";
const AXIOS_REQUEST_SUCCESS = "AXIOS_REQUEST_SUCCESS";
const AXIOS_REQUEST_FAIL = "AXIOS_REQUEST_FAIL";
const AXIOS_PRE_REQUEST = "AXIOS_PRE_REQUEST";

const Instance = axios.create({
	baseURL: API_URL,
	headers: { "content-type": MIME_JSON }
});

Instance.interceptors.request.use(
	config => {
		const user = _get(store.getState(), "auth.user");
		const isLoggedIn = _get(store.getState(), "auth.isLoggedIn");

		if (user && user.access_token) {
			config.headers.common["Authorization"] = `Bearer ${user.access_token}`;
		}
		// use extension cookie
		else if (!isLoggedIn && getCookie(COOKIE_TOKEN)) {
			config.headers.common["Authorization"] = `Bearer ${getCookie(
				COOKIE_TOKEN
			)}`;
		}
		//should accept only JSON format
		config.headers.post["Accept"] = "application/json";
		return config;
	},
	error => Promise.reject(error)
);

Instance.interceptors.response.use(response => {
	// TODO: this interceptor need to be refactored and place to code in the predispatch lifecycle
	const user = getLocalUser();
	const location = window.location.pathname;
	if (
		user &&
		user.company_status !== STATUS_ACTIVATED &&
		location.indexOf("company") < 0 &&
		location.indexOf("policies") < 0
	) {
		const isClient = user.company_type === CLIENT;
		if (!user.type) {
			const step = _get(user.company_status.split("_"), "1", IDENTITY);
			if (!isClient) {
				browserHistory.push(`/company/${step}`);
			}
		}
		// return Promise.reject("you don't have access to this page yet");
	}

	return response;
});

const AxiosValidator = ({ method, url, data }) => {
	return new Promise((resolve, reject) => {
		if (method !== HTTP_GET && method !== HTTP_POST) {
			reject(new TypeError(`Method should either be GET or POST`));
		}
		if (method === HTTP_POST && !data) {
			reject(new TypeError(`data is a required parameter for post methods`));
		}
		if (!url) {
			reject(new TypeError(`The url is a required parameter`));
		}
		resolve(data ? JSON.stringify(data) : undefined);
	});
};

export const getBackendError = (error, reduxFormName) => {
	const status = _get(error, "response.status");
	let response = _get(
		error,
		"response.data.detail",
		_get(error, "response.detail")
	);
	let title = _get(error, "response.data.title");
	if (typeof error === "string") {
		response = error;
	}
	switch (status) {
		case 400:
			return "Invalid content type.";
		case 401:
			store.dispatch(receiveLogout({ redirect: true }));
			return "Not authorized.";
		case 403:
			return "Permission denied.";
		case 404:
			return response || "Not found.";
		case 405:
			return "Method not allowed";
		case 422:
			if (_isObject(response) && !reduxFormName) {
				if (response.blocked_account || response.blocked) {
					return response;
				}
				for (const key in response) {
					return _isObject(response[key])
						? `${key} : ${response[key][0]}`
						: response[key];
				}
			}
			return response;
		case 500:
			if (title && typeof title === "string") return `${status} : ${title}`;
			return "Unexpected backend error.";
		case 502:
		case 503:
		case 504:
			return "API down.";
		default:
			return response;
	}
};

const getBackEndReponse = ({ status, data }) => {
	// 200 => OK, 202 => Accepted, 204 => No content
	if (status === 200 || status === 202 || status === 204) {
		return data;
	}
	return false;
};

// prevent making actions once the user has been disconnected
const shouldMakeAction = isLogin => getLocalUser() || isLogin;

const doAction = ({
	url,
	type,
	data = {},
	next, // if you need to dispatch another action after success, use next: data => dispatch
	afterNext, // if you need to dispatch another action after next, use afterNext: data => dispatch
	formatter, // if you want to change response data shape before returning/dispatching it
	method,
	newRoute, // use in case you wanted to change routes after action success
	toastMessage, // success message (error messages are managed here)
	toasterID, // for unique toaster
	toasterDuration = 5, // toaster duration
	manageByDefault = true, // manages default app props changes (loading, succeded, failed...) by dispatching AXIOS_PRE_REQUEST, AXIOS_REQUEST_SUCCESS and AXIOS_REQUEST_FAIL
	reduxFormName, // if you are using redux form and want backend errors to be managed by default, provide your redux-form's name
	loadingText,
	actionName,
	isModal = false,
	isLogin,
	inAction = true
	// optional in case you needed to check for a response status concerning a specific action
}) => {
	return new Promise((resolve, reject) => {
		return (
			shouldMakeAction(isLogin) &&
			AxiosValidator({ method, url, data }).then(stringifiedData => {
				if (!type && manageByDefault) {
					store.dispatch({
						type: AXIOS_PRE_REQUEST,
						loadingText,
						actionName,
						isModal,
						method,
						url,
						inAction
					});
				}
				return Instance({
					data: stringifiedData,
					method,
					url
				})
					.then(response => {
						const toasterSettings = { duration: toasterDuration };
						if (toasterID) toasterSettings.id = toasterID;
						toastMessage && toaster.success(toastMessage, toasterSettings);
						data = getBackEndReponse(response) || data;
						manageByDefault &&
							store.dispatch({
								type: AXIOS_REQUEST_SUCCESS,
								inAction: !!next,
								loadingText: next ? loadingText : "",
								actionName,
								isModal,
								url
							});
						async function callNext(step) {
							const nextRes = await store.dispatch(
								step === "next"
									? next(data, reject, response)
									: afterNext(data, reject, response)
							);
							if (nextRes === undefined) {
								throw new Error(
									`the next action you used after ${url} should have a return statement`
								);
							}
							if (nextRes && nextRes.type !== variables.TYPE) {
								if (axiosPropsSelectors(store.getState()).inAction) {
									store.dispatch({
										type: AXIOS_REQUEST_SUCCESS,
										inAction: false,
										isModal
									});
								}
							}
						}
						next && callNext("next");
						next && afterNext && callNext("afterNext");
						newRoute && browserHistory.push(newRoute);
						resolve(!data || (formatter ? formatter(data) : data));
					})
					.catch(error => {
						let _error = getBackendError(error, reduxFormName);
						manageByDefault &&
							store.dispatch({
								type: AXIOS_REQUEST_FAIL,
								actionName,
								error: _error,
								isModal,
								url
							});
						if (reduxFormName && _isObject(_error)) {
							store.dispatch(stopSubmit(reduxFormName, _error));
						} else {
							toaster.danger(
								typeof _error === "string"
									? _error
									: _error?.message ?? "Error",
								{ id: "err" }
							);
						}
						reject(error.response);
					});
			})
		);
	});
};

const dispatchDoAction = ({
	data,
	PRE_REQUEST,
	REQUEST_SUCCESS,
	REQUEST_FAIL,
	newRoute,
	...props
}) => {
	return dispatch => {
		PRE_REQUEST && dispatch({ type: PRE_REQUEST, data });
		return new Promise((resolve, reject) =>
			doAction({
				data,
				...props
			})
				.then(data => {
					REQUEST_SUCCESS && dispatch({ type: REQUEST_SUCCESS, data });
					newRoute && browserHistory.push(newRoute);
					resolve(data);
				})
				.catch(error => {
					if (error?.status === 401) {
						logout();
						window.location.href = "/sign-in";
						return;
					}
					REQUEST_FAIL && dispatch({ type: REQUEST_FAIL, data, error });
					reject(error);
				})
		);
	};
};

const AxiosHelper = {
	findMethod: props => {
		// always return underscored methods for middle methods
		if (props.method === variables.GET) {
			return AxiosHelper.__get;
		}
		return AxiosHelper.__post;
	},
	get: props => {
		return doAction({
			...props,
			method: HTTP_GET
		});
	},
	post: props => {
		return doAction({
			...props,
			method: HTTP_POST
		});
	},
	__get: props => {
		return dispatchDoAction({ ...props, method: HTTP_GET });
	},
	__post: props => {
		return dispatchDoAction({ ...props, method: HTTP_POST });
	}
};

const actionsMiddleware = store => next => action => {
	if (!action.type) {
		throw new Error(
			`This action requires a type, check the method with the url : ${action.url}`
		);
	}

	if (action.type === variables.TYPE) {
		if (!action.method) {
			throw new Error(
				`This action requires a method name, check the method with the url : ${action.url}`
			);
		}
		const __action = AxiosHelper.findMethod(action);
		store.dispatch(__action(action));
	}

	return next(action);
};

const variables = {
	GET: HTTP_GET,
	POST: HTTP_POST,
	TYPE: ACTION_CREATOR
};

const post = AxiosHelper.post;
const get = AxiosHelper.get;

export { variables, endpoints, post, get, actionsMiddleware };

export default AxiosHelper;
