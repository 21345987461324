import React, { useState, useEffect, useRef, memo } from "react";
import styled, { keyframes } from "styled-components";
import Transition from "react-transition-group/Transition";
import { ReactComponent as IconToastClose } from "static/icons/IconToastClose.svg";
import PropTypes from "prop-types";
import {
	ToasterText,
	ToasterClose,
	getToasterType,
	ToasterTextContainer
} from "./ToasterStyled";
import Icon from "./icons";
import ProgressBar from "./ProgressBar";
import useWindowSize from "hooks/useWindowSize";

const animationEasing = {
	deceleration: `cubic-bezier(0.0, 0.0, 0.2, 1)`,
	acceleration: `cubic-bezier(0.4, 0.0, 1, 1)`,
	spring: `cubic-bezier(0.175, 0.885, 0.320, 1.175)`
};

const ANIMATION_DURATION = 240;

const openAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-120%);
  }
  to {
    transform: translateY(0);
  }
`;

const closeAnimation = keyframes`
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0.9);
    opacity: 0
  }
`;

const AnimatedDiv = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 0;
	transition: all ${ANIMATION_DURATION}ms ${animationEasing.deceleration};
	&[data-state="entering"],
	&[data-state="entered"] {
		animation: ${openAnimation} ${ANIMATION_DURATION}ms
			${animationEasing.spring} both;
	}
	&[data-state="exiting"] {
		animation: ${closeAnimation} 120ms ${animationEasing.acceleration} both;
	}
`;

function Toast(props) {
	const [isVisible, setIsVisible] = useState(props.isVisible);
	const [isProgressVisible, setIsProgressVisible] = useState(true);
	const [height, setHeight] = useState(0);

	useEffect(() => {
		setIsVisible(props.isVisible);
	}, [props.isVisible]);

	useEffect(() => {
		startCloseTimer();
		return () => clearCloseTimer();
	}, []);

	const onRef = ref => {
		if (ref === null) return;
		const { height } = ref.getBoundingClientRect();
		setHeight(height);
	};

	const closeTimer = useRef();

	const startCloseTimer = () => {
		if (props.duration) {
			closeTimer.current = setTimeout(() => {
				close();
			}, props.duration * 1000);
			setIsProgressVisible(true);
		}
	};

	const handleMouseEnter = () => {
		clearCloseTimer();
	};

	const handleMouseLeave = () => {
		startCloseTimer();
	};

	const clearCloseTimer = () => {
		if (closeTimer.current) {
			clearTimeout(closeTimer.current);
			closeTimer.current = null;
		}
		setIsProgressVisible(false);
	};

	const close = () => {
		clearCloseTimer();
		setIsVisible(false);
	};

	const ToasterStyled = getToasterType(props.intent);

	const isMobile = useWindowSize();
	return (
		<Transition
			appear
			unmountOnExit
			timeout={ANIMATION_DURATION}
			in={isVisible}
			onExited={props.onRemove}
		>
			{state => (
				<AnimatedDiv
					data-state={state}
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					style={{
						height: height,
						marginBottom: isVisible ? 0 : -height
					}}
				>
					<div ref={onRef}>
						<ToasterStyled>
							<ToasterTextContainer>
								{props.icon || <Icon intent={props.intent} />}
								<ToasterText width={isMobile ? "auto" : "300px"}>
									{typeof props?.title === "string" ? (
										<div
											className="text"
											dangerouslySetInnerHTML={{ __html: props?.title }}
										/>
									) : typeof props.title === "function" ? (
										props.title()
									) : null}
								</ToasterText>
							</ToasterTextContainer>
							{props.closable && (
								<ToasterClose onClick={() => close()}>
									<IconToastClose />
								</ToasterClose>
							)}
							{isProgressVisible && (
								<ProgressBar active={true} duration={props.duration} />
							)}
						</ToasterStyled>
					</div>
				</AnimatedDiv>
			)}
		</Transition>
	);
}

Toast.propTypes = {
	duration: PropTypes.number,
	onRemove: PropTypes.func,
	intent: PropTypes.oneOf(["base", "success", "warning", "danger", "time"])
		.isRequired,
	title: PropTypes.node,
	children: PropTypes.node,
	closable: PropTypes.bool,
	isVisible: PropTypes.bool
};

Toast.defaultProps = {
	intent: "success",
	isVisible: true
};

export default memo(Toast);
