import React from "react";
import styles from "./messaging-tool-email-view-message.module.scss";
import Avatar from "common/Avatar";
import { ReactComponent as ChevronIcon } from "static/icons/chevron-down.svg";
import { ReactComponent as FileIcon } from "static/icons/file2.svg";
import { ReactComponent as UploadIcon } from "static/icons/upload-02.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import Skeleton from "react-loading-skeleton";
import { Button } from "common/Button";
import {
	bytesToSize,
	downloadFileFromUrl,
	historyPush,
	renderError
} from "config/helpers";
import MessagingToolEmailViewMessageActions from "../MessagingToolEmailViewMessageActions/MessagingToolEmailViewMessageActions";
import useDownloadAttachment from "common/MessagingToolEmailView/api/useDownloadAttachment";
import { CLICKABLE_PROFILE_TYPE } from "common/MessagingToolEmailView/utils/constants";
import cx from "classnames";
import { FREELANCER, PERMANENT } from "config";
import { getPermanentProfileRoute } from "modules/candidate/Profile";
import { useGetUser } from "hooks/useGetUser";
import get from "lodash/get";
import RenderEmail from "../render-email/render-email";
import Tooltip from "common/Tippy";

const MessagingToolEmailViewMessage = ({
	isLoading,
	fromName,
	messageId,
	toName,
	createdAt,
	cc,
	bcc,
	content,
	emailData,
	attachments,
	onClickForward,
	onClickReply
}) => {
	const dropdownRef = React.useRef(null);
	const [showDetails, setShowDetails] = React.useState(false);
	const [download] = useDownloadAttachment();
	useOnClickOutside(dropdownRef, () => {
		setShowDetails(false);
	});
	const user = useGetUser();

	React.useEffect(() => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(content, "text/html");

		const collapsible = doc.querySelector("div.gmail_quote");
		if (collapsible) {
			const wrapper = doc.createElement("div");
			wrapper.innerHTML = `<button class="--replyCollapseTrigger --replyCollapsed" onclick="this.classList.toggle('--replyCollapsed')"><svg width="24" height="24" viewBox="0 0 24 24"><path d="M16,12C16,10.9 16.9,10 18,10C19.1,10 20,10.9 20,12C20,13.1 19.1,14 18,14C16.9,14 16,13.1 16,12M10,12C10,10.9 10.9,10 12,10C13.1,10 14,10.9 14,12C14,13.1 13.1,14 12,14C10.9,14 10,13.1 10,12M4,12C4,10.9 4.9,10 6,10C7.1,10 8,10.9 8,12C8,13.1 7.1,14 6,14C4.9,14 4,13.1 4,12Z" /></svg></button>`;
			const trigger = wrapper.firstChild;
			collapsible.parentElement.insertBefore(trigger, collapsible);
		}
	}, [content, attachments, messageId]);

	const onDownload = file => {
		if (file.is_inline) {
			download(
				{
					body: {
						ids: [file.id],
						grant_id: file.grant_id,
						message_id: messageId,
						as_base64: true
					},
					isBlob: false
				},
				{
					onSuccess: data => {
						downloadFileFromUrl(data[file.id], file.filename);
					},
					onError: renderError
				}
			);

			return;
		}
		if (!file.url) {
			download(
				{
					body: {
						ids: [file.id],
						grant_id: file.grant_id,
						message_id: messageId
					},
					isBlob: true
				},
				{
					onSuccess: blob => {
						const url = window.URL.createObjectURL(new Blob([blob]));
						downloadFileFromUrl(url, file.filename);
					},
					onError: renderError
				}
			);
			return;
		}
		downloadFileFromUrl(file.url, file.filename);
	};

	const onRedirectToProfile = (userType, userId) => {
		const routes = {
			[FREELANCER]: `/user/profile/${userId}`,
			[PERMANENT]: getPermanentProfileRoute(userId)
		};

		if (routes[userType]) {
			return historyPush(routes[userType]);
		}
	};

	return (
		<div className={styles.container}>
			{isLoading ? (
				<Skeleton circle width={36} height={36} />
			) : (
				<div
					className={cx(styles.avatarWrapper, {
						[styles.clickable]: CLICKABLE_PROFILE_TYPE.includes(
							get(emailData, "from[0].type")
						)
					})}
					onClick={() =>
						onRedirectToProfile(
							get(emailData, "from[0].type"),
							get(emailData, "from[0]._id")
						)
					}
				>
					<Avatar
						name={fromName}
						radius={100}
						size={36}
						className={styles.avatar}
					/>
				</div>
			)}
			<div className={styles.content}>
				<header className={styles.header}>
					<div className={styles.info}>
						{isLoading ? (
							<Skeleton width={135} height={10} />
						) : (
							<h3
								className={cx(styles.username, {
									[styles.clickable]: CLICKABLE_PROFILE_TYPE.includes(
										get(emailData, "from[0].type")
									)
								})}
								onClick={() =>
									onRedirectToProfile(
										get(emailData, "from[0].type"),
										get(emailData, "from[0]._id")
									)
								}
							>
								{fromName} <span className={styles.date}>{createdAt}</span>
							</h3>
						)}
						{isLoading ? (
							<Skeleton width={135} height={10} />
						) : (
							<span
								className={styles.receiver}
								ref={dropdownRef}
								onClick={() => setShowDetails(prev => !prev)}
								role="button"
							>
								To: {toName}
								<button className={styles.cta}>
									<ChevronIcon className={styles.chevronIcon} />
								</button>
								{showDetails && (
									<div className={styles.dropdown}>
										<div className={styles.field}>
											<span className={styles.key}>From: </span>
											<div className={styles.value}>
												{emailData.from.map((item, index) => {
													return (
														<div
															key={item.email}
															className={cx({
																[styles.clickable]: CLICKABLE_PROFILE_TYPE.includes(
																	item.type
																)
															})}
															onClick={() =>
																onRedirectToProfile(item.type, item._id)
															}
														>
															<span>
																{user.email === item.email ? "Me" : item.name} (
																{item.email})
																{index !== emailData.from.length - 1 && ","}
															</span>
														</div>
													);
												})}
											</div>
										</div>
										<div className={styles.field}>
											<span className={styles.key}>To: </span>
											<div className={styles.value}>
												{emailData.to.map((item, index) => {
													return (
														<div
															key={item.email}
															className={cx({
																[styles.clickable]: CLICKABLE_PROFILE_TYPE.includes(
																	item.type
																)
															})}
															onClick={() =>
																onRedirectToProfile(item.type, item._id)
															}
														>
															<span>
																{user.email === item.email ? "Me" : item.name} (
																{item.email})
																{index !== emailData.to.length - 1 && ","}
															</span>
														</div>
													);
												})}
											</div>
										</div>
										{cc.length !== 0 && (
											<div className={styles.field}>
												<span className={styles.key}>Cc: </span>
												<div className={styles.value}>{cc}</div>
											</div>
										)}
										{bcc.length !== 0 && (
											<div className={styles.field}>
												<span className={styles.key}>Bcc: </span>
												<div className={styles.value}>{bcc}</div>
											</div>
										)}
										<div className={styles.field}>
											<span className={styles.key}>security: </span>
											<div className={styles.value}>
												Standard encryption TLS
											</div>
										</div>
									</div>
								)}
							</span>
						)}
					</div>
					<MessagingToolEmailViewMessageActions
						emailData={emailData}
						onClickForward={onClickForward}
						onClickReply={onClickReply}
					/>
				</header>
				{isLoading ? (
					Array(4)
						.fill(0)
						.map(i => <Skeleton key={i} width={874} height={10} />)
				) : (
					<RenderEmail
						html={content}
						attachments={attachments}
						messageId={messageId}
					/>
				)}
				{attachments?.length !== 0 && (
					<div className={styles.attachments}>
						{attachments?.map(file => (
							<div className={styles.attachementFile} key={file.url}>
								<FileIcon className={styles.fileIcon} />
								<div className={styles.fileInfo}>
									<Tooltip
										overflow="hidden"
										content={file.filename}
										theme="dark"
									>
										<span className={styles.name}>{file.filename}</span>
									</Tooltip>
									<span className={styles.size}>{bytesToSize(file.size)}</span>
								</div>
								<Button
									type="button"
									variant="text"
									icon={<UploadIcon />}
									rootClassName={styles.uploadButtonWrapper}
									className={styles.uploadButton}
									onClick={() => onDownload(file)}
								/>
							</div>
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default MessagingToolEmailViewMessage;
