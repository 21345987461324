import { getLocalTimeZone, today } from "@internationalized/date";
import { ALLOWED_FILES_TYPES } from "./constant";
import toaster from "common/Toaster";
import { dateToTimestamp } from "config/helpers";
import moment from "moment";
const toBase64 = file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = reject;
	});
};

export function base64ToFile(base64String, fileName, mimeType) {
	const byteString = atob(base64String);

	const byteNumbers = new Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		byteNumbers[i] = byteString.charCodeAt(i);
	}

	const byteArray = new Uint8Array(byteNumbers);
	const blob = new Blob([byteArray], { type: mimeType });

	return new File([blob], fileName, { type: mimeType });
}

export const formatFilesToBase64 = async files => {
	const formattedFilesPromises = files.map(async attachment => {
		if (attachment.content?.includes("base64"))
			return {
				filename: attachment.file.name,
				content_type: attachment.content_type,
				content: attachment.content,
				url: attachment.url
			};
		const { file, url } = attachment;
		const base64Content = await toBase64(file);
		return {
			filename: file.name,
			content_type: file.type,
			content: base64Content.split(",")[1],
			url: url
		};
	});

	return Promise.all(formattedFilesPromises);
};

export async function formatData(data) {
	const formattedAttachments = await formatFilesToBase64(data.attachments);

	return {
		subject: data.subject,
		content: data.content,
		to: data.to.map(user => ({ email: user.email })),
		cc: data.cc?.map(user => ({ email: user.email })),
		bcc: data.bcc?.map(user => ({ email: user.email })),
		content_placeholders: data.content_placeholders.map(placeholder => ({
			key: placeholder.value,
			value: placeholder._id || ""
		})),
		attachments: formattedAttachments
	};
}

const isEveryFileTypeValid = files => {
	return files.every(item => ALLOWED_FILES_TYPES.includes(item.type));
};

export const isFilesValid = files => {
	let result = true;
	if (!isEveryFileTypeValid(files)) {
		toaster.danger("The file type is invalid");
		result = false;
	}

	return result;
};

export const filterAlreadySelectedParticipants = (data, participants) => {
	return data?.filter(user => !participants.includes(user.email));
};

export const getListPlaceholdersType = (placeholders = []) => {
	const placeholdersDataTypes = placeholders.map(element => element.entity);

	return Array.from(new Set(placeholdersDataTypes));
};

export const getCurrentTimestamp = () => {
	const currentDate = today(getLocalTimeZone());

	return dateToTimestamp(
		`${currentDate.day}-${currentDate.month}-${currentDate.year}`
	);
};

const convertDate = value => {
	return moment(`${value.day}-${value.month}-${value.year}`, "DD-MM-YYYY");
};

export const dateAndTimeToTimestamp = ({ date, time }) => {
	const momentDay = convertDate(date);
	const { hour, minute } = time;

	return momentDay
		.set({
			hour,
			minute
		})
		.utc()
		.unix();
};

export const parseHTMLAndExtractSignature = htmlString => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(htmlString, "text/html");
	const signatureElements = doc.querySelectorAll("[data-signature]");

	let signature = null;
	Array.from(signatureElements).forEach(element => {
		const signatureData = element.getAttribute("data-signature");
		signature = JSON.parse(signatureData);
	});

	return signature;
};

export const isPlaceholdersValid = placeholders => {
	return !placeholders.some(placeholder => placeholder.value === "");
};
