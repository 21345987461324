import { useQuery } from "react-query";
import { client } from "lib/api-client";
import { DOWNLOAD_ATTACHMENT } from "config/api-endpoints";

function getInlineImageBase64({ body, isBlob }) {
	return client(DOWNLOAD_ATTACHMENT, {
		body,
		isBlob
	});
}

function useGetInlineImagesBase64(body, options) {
	return useQuery([body], () => getInlineImageBase64(body), {
		refetchOnWindowFocus: false,
		retry: 0,
		...options
	});
}

export default useGetInlineImagesBase64;
