import React, { Component } from "react";
import _get from "lodash/get";
import { Link } from "react-router";
import _forEach from "lodash/forEach";
import { getUserLinks } from "config/app-links";
import { getLocalUser } from "config/helpers";
import { TopContainer } from "../styled/CommonStyled";
import QS from "query-string";
import { connect } from "react-redux";
import { onlineUserSelector } from "modules/user/selectors/selectors";
import { PERMANENT } from "config";
import cx from "classnames";

export const getDashboardRoute = () => {
	return "/reporting/dashboard";
};

export const getDashboardPermission = () => {
	return {
		type: [
			{
				name: "client",
				roles: ["admin", "project_manager"]
			}
		]
	};
};

class CompanyDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			links: []
		};
		this.getLinks = this.getLinks.bind(this);
		this.renderItems = this.renderItems.bind(this);
		this.hasActiveSub = this.hasActiveSub.bind(this);
		this.user = getLocalUser();
	}

	componentDidMount() {
		let { pathname, search } = window.location;

		if (/\/$/.test(pathname)) pathname = pathname.slice(0, -1); // remove last /
		this.rootRoute = pathname + search;

		const userType = _get(this.user, "company_type") || _get(this.user, "type");
		this.getLinks(getUserLinks(userType, _get(this.user, "role_name")));
	}

	getLinks(data) {
		let children = {
			items: []
		};
		_forEach(data, parent => {
			_forEach(parent.children, child => {
				if (this.rootRoute.includes(child.route)) {
					children.items = parent.children;
				}
			});
		});
		this.setState({
			links: children.items
		});
	}

	hasActiveSub(link) {
		let found = false;
		const qsSearch = QS.parse(window.location.search);
		const fromMyCandidates = qsSearch.pool === "my-candidates";
		for (const route of link.subRoutes) {
			if (link.label === "search" && fromMyCandidates) {
				continue;
			} else if (this.rootRoute.includes(route)) {
				found = true;
				break;
			}
		}
		return found;
	}

	renderItems() {
		return this.state.links.map((link, index) => {
			const activeSub = link.subRoutes && this.hasActiveSub(link);
			if (!link.hidden) {
				return (
					<li key={`${index}-${link.label}`}>
						<Link
							to={link.route}
							className={activeSub ? "active" : ""}
							activeClassName="active"
						>
							{link.label}
						</Link>
					</li>
				);
			} else return true;
		});
	}

	render() {
		const { subClass, isPermanent, isPermanentView, className } = this.props;

		const permanentClassName =
			this.props.user?.type === PERMANENT ? "dashboard-permanent" : "";

		const candidateViewClassName = isPermanentView
			? "dashboard-candidate-view"
			: "";

		return (
			<div
				className={cx(
					subClass
						? subClass
						: isPermanent
						? "dashboard-candidate"
						: `dashboard ${permanentClassName} ${candidateViewClassName}`,
					className
				)}
			>
				<div className="row">
					<TopContainer className="container">
						<ul>{this.renderItems()}</ul>
					</TopContainer>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: onlineUserSelector(state)
	};
};

export default connect(mapStateToProps)(CompanyDashboard);
