import React from "react";
import {
	today,
	getLocalTimeZone,
	Time,
	isSameDay
} from "@internationalized/date";
import styles from "./vacancy-view-propose-interview-first-step.module.scss";
import { ReactComponent as ClockIcon } from "static/icons/clock-1.svg";
import { ReactComponent as UsersIcon } from "static/icons/users.svg";
import { ReactComponent as CalendarPlusIcon } from "static/icons/calendar-plus-1.svg";
import { ReactComponent as MapPinIcon } from "static/icons/map-pin.svg";
import { ReactComponent as CommentIcon } from "static/icons/comment-1.svg";
import Calendar from "common/DatePicker/Calendar/Calendar";
import Avatar from "common/Avatar/index";
import { VacancyViewAttendeesPicker } from "../../VacancyViewAttendeesPicker";
import { Controller, useFormContext } from "react-hook-form";
import { generateId, sortCalendarDates } from "config/helpers";
import { VacancyViewTimesList } from "../../VacancyViewTimesList";
import { VacancyViewLocationPicker } from "../../VacancyViewLocationPicker";
import { FieldError } from "common/FieldError";
import VacancyViewPlannedInterviews from "../../VacancyViewPlannedInterviews/VacancyViewPlannedInterviews";
import useVacancyStore from "../../vacancyStore";
import { MAX_DAYS_SELECTED } from "../../helper/constants";

export default function VacancyViewProposeInterviewFirstStep({
	isVisible,
	job
}) {
	const {
		control,
		watch,
		setValue,
		getValues,
		formState: { errors }
	} = useFormContext();
	const { drawerState } = useVacancyStore();

	const onDateChange = (date, dates = [], onChange) => {
		const index = dates.findIndex(item => isSameDay(item, date));

		if (index !== -1) {
			dates.splice(index, 1);
			const timesResult = getValues("times").filter(
				time => !isSameDay(time.date, date)
			);
			setValue("times", timesResult);
			onChange([...dates]);
		} else if (dates.length === MAX_DAYS_SELECTED) {
			return;
		} else {
			addTime(date);
			const datesResult = [...dates, date];
			datesResult.sort(sortCalendarDates);
			onChange(datesResult);
		}
	};

	const filterTimes = date => {
		return getValues("times").filter(item => isSameDay(item.date, date));
	};

	const onAdd = date => addTime(date);
	const onRemove = id => {
		const timesResult = getValues("times").filter(time => time.id !== id);
		setValue("times", timesResult);
	};

	const addTime = date => {
		const time = {
			id: generateId(),
			date,
			startTime: new Time(10, 0),
			endTime: new Time(11, 0)
		};
		setValue("times", [...getValues("times"), time], {
			shouldValidate: true
		});
	};

	return (
		<>
			<div
				className={styles.form}
				style={{ display: isVisible ? "block" : "none" }}
			>
				<div className={styles.formTitle}>
					Plan and Notify {drawerState.candidateName} with the interview details
				</div>
				<div className={styles.candidateInfos}>
					<Avatar
						className={styles.avatar}
						size={42}
						src={drawerState.avatar}
						name={drawerState.candidateName}
					/>
					<div>
						<div className={styles.candidateName}>
							{drawerState.candidateName}
						</div>
						<div className={styles.candidatePosition}>
							{drawerState.mainFunction}
						</div>
					</div>
				</div>
				<div className={styles.fields}>
					<div>
						<div className={styles.label}>
							<UsersIcon />
							<span>Invite attendees</span>
						</div>
						<VacancyViewAttendeesPicker
							selectedAttendees={watch("attendees")}
							job={job}
						/>
						<FieldError className={styles.error} error={errors.attendees} />
					</div>
					<div>
						<div className={styles.label}>
							<CalendarPlusIcon />
							<span>Date & Time</span>
						</div>
						<div className={styles.calendarContainer}>
							<Controller
								name="dates"
								control={control}
								render={({ field: { onChange, value } }) => {
									return (
										<div>
											<Calendar
												onChange={day => onDateChange(day, value, onChange)}
												isMultiple={Array.isArray(value)}
												className={styles.calendar}
												minValue={today(getLocalTimeZone())}
												list={value}
												cellClassName={styles.cellClassName}
											/>
										</div>
									);
								}}
							/>
							<Controller
								control={control}
								name="times"
								render={({ field: { onChange } }) => (
									<div>
										<div className={styles.slotsPicker}>
											<div className={styles.slotsHeader}>
												<ClockIcon />
												<span>Select time</span>
											</div>
											<div className={styles.timesList}>
												{watch("dates").map(date => (
													<VacancyViewTimesList
														times={filterTimes(date)}
														date={date}
														key={date}
														onChange={onChange}
														onAdd={onAdd}
														onRemove={onRemove}
														allTimes={watch("times")}
													/>
												))}
											</div>
										</div>
									</div>
								)}
							/>
						</div>
						<FieldError className={styles.error} error={errors.dates} />
						{!Array.isArray(errors.times) && (
							<FieldError className={styles.error} error={errors.times} />
						)}
					</div>
					<div>
						<div className={styles.label}>
							<MapPinIcon />
							<span>Location</span>
						</div>
						<div className={styles.locationPicker}>
							<VacancyViewLocationPicker />
							<FieldError
								className={styles.error}
								error={errors.locationValue}
							/>
						</div>
					</div>
					<div className={styles.noteContainer}>
						<div className={styles.label}>
							<CommentIcon />
							<span>Notes</span>
						</div>

						<Controller
							name="note"
							control={control}
							render={({ field: { value, onChange } }) => (
								<textarea
									placeholder="Start typing..."
									className={styles.textarea}
									value={value}
									onChange={e => onChange(e.target.value)}
								/>
							)}
						/>
						<FieldError className={styles.error} error={errors.note} />
					</div>
				</div>
			</div>
			<div
				className={styles.sidebar}
				style={{ display: isVisible ? "block" : "none" }}
			>
				<VacancyViewPlannedInterviews job={job} />
			</div>
		</>
	);
}
