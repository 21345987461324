import React from "react";
import { ReactComponent as IconToastDanger } from "static/icons/IconToastDanger.svg";
import { ReactComponent as IconToastInfo } from "static/icons/IconToastInfo.svg";
import { ReactComponent as IconToastWarning } from "static/icons/IconToastWarning.svg";
import { ReactComponent as IconToastSuccess } from "static/icons/IconToastSuccess.svg";
import { ReactComponent as IconToastTime } from "static/icons/clock-01.svg";

function getIcon(intent) {
	switch (intent) {
		case "success":
			return <IconToastSuccess />;
		case "danger":
			return <IconToastDanger />;
		case "warning":
			return <IconToastWarning style={{ minWidth: "max-content" }} />;
		case "time":
			return <IconToastTime />;
		default:
			return <IconToastInfo />;
	}
}

export default function Icon({ intent = "info" }) {
	return getIcon(intent);
}
