import React, { useRef, useState } from "react";
import style from "./header-button.module.scss";
import useOnClickOutside from "hooks/useOnClickOutside";
import classnames from "classnames";
import Loader from "./components/Loader/Loader";
import Tooltip from "common/Tippy";
const HeaderButton = ({
	type = "button",
	className,
	text,
	icon,
	secondIcon,
	onClick,
	active,
	count,
	children,
	variant,
	side = "right",
	isBlock = "isBlock",
	disabled,
	isLoading = false,
	noSelection,
	buttonClassName,
	visibility,
	visibilitySearch,
	countClassName,
	...rest
}) => {
	const [showMenu, setShowMenu] = useState(false);
	const menuRef = useRef(null);
	const ref = useRef();
	const text_ref = useRef();

	useOnClickOutside(menuRef, () => {
		setShowMenu(false);
	});

	const clickHandler = () => {
		if (children) {
			setShowMenu(old => !old);
			return;
		}
		if (onClick) {
			onClick();
		}
		return;
	};
	return (
		<div
			ref={menuRef}
			data-onboarding-step={rest["data-onboarding-step"]}
			className={classnames(style.btnRoot, {
				[style.visibility]: visibility,
				[style.visibilitySearch]: visibilitySearch
			})}
		>
			<button
				ref={ref}
				disabled={disabled}
				className={classnames(style[className], buttonClassName, {
					[style.expanded]: showMenu,
					[style.active]: active,
					[style.disabled]: disabled,
					[style.fixed]: variant === "fixed-width",
					[style.big]: variant === "big",
					[style.isBlock]: isBlock,
					[style.noSelection]: noSelection
				})}
				onClick={clickHandler}
				type={type}
			>
				{isLoading ? (
					<Loader />
				) : (
					<>
						{icon ? icon : null}
						{text ? (
							<Tooltip content={text} theme="dark" overflow="hidden">
								<span
									ref={text_ref}
									className={classnames(style.buttonTextContainer)}
								>
									{text}
								</span>
							</Tooltip>
						) : null}

						<span className={countClassName}>{count ? count : null}</span>
						{secondIcon && !disabled ? secondIcon : null}
					</>
				)}
			</button>

			{showMenu && (
				<div
					className={classnames(style.btn_content, {
						[style[side]]: true,
						[style.wide]: variant === "wide",
						[style.visibility]: visibility
					})}
				>
					{React.Children.map(children, child => {
						return <>{React.cloneElement(child, { setShowMenu })}</>;
					})}
				</div>
			)}
		</div>
	);
};

export default HeaderButton;
