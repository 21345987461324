import React from "react";
import PropTypes from "prop-types";
import Avatar from "common/Avatar/index";
import {
	UNREAD,
	DEFAULT_USER_NAME,
	SEND_BULK_PROCESS_COMPLETED_NOTIFICATION
} from "config";
import { ReactComponent as SystemNotification } from "static/icons/announcement.svg";
import styles from "../style.module.scss";
import { ReactComponent as ArrowRightIcon } from "static/icons/arrow-right2.svg";
import { SEND_EXPORTED_DATA_COMPLETED_NOTIFICATION } from "config";
import { ReactComponent as DownloadIcon } from "static/icons/download-icon-2.svg";

const NotificationRow = ({ notification, onRead }) => {
	const timeFromNow = window.moment
		.duration(window.moment.unix(notification.created_at) - window.moment())
		.humanize(true);

	return (
		<React.Fragment>
			<div onClick={() => onRead(notification)} className={styles.notif_item}>
				{notification.message.type !== "system" &&
				notification?.user_sender?.first_name ? (
					<Avatar
						src={notification.user_sender.avatar}
						fallbackText={DEFAULT_USER_NAME}
						name={`${notification.user_sender.first_name} ${notification.user_sender.last_name}`}
						size={35}
						radius={8}
					/>
				) : (
					<div className={styles.notif_icon}>
						<SystemNotification />
					</div>
				)}
				<div className={styles.notif_content}>
					<div className={styles.notif_content_header}>
						<div>
							<div className={styles.name}>
								{notification.message.type == "system"
									? "System notifications"
									: !!(
											notification.user_sender?.first_name &&
											notification.user_sender?.last_name
									  ) &&
									  `${notification.user_sender.first_name} ${notification.user_sender.last_name}`}
							</div>
							<div className={styles.time}>{timeFromNow}</div>
						</div>
						{notification.status === UNREAD && (
							<div className={styles.unread_badge} />
						)}
					</div>
					<div
						className={styles.text}
						dangerouslySetInnerHTML={{
							__html: notification.message.content
						}}
					></div>
					{notification.type === SEND_BULK_PROCESS_COMPLETED_NOTIFICATION && (
						<button className={styles.buttonWrapper}>
							<span className={styles.viewDetails}>View details</span>
							<ArrowRightIcon width={20} height={20} />
						</button>
					)}
					{notification.type === SEND_EXPORTED_DATA_COMPLETED_NOTIFICATION && (
						<button className={styles.buttonWrapper}>
							<span className={styles.viewDetails}>Download file</span>
							<DownloadIcon width={20} height={20} />
						</button>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

NotificationRow.propTypes = {
	notification: PropTypes.object.isRequired
};

export default NotificationRow;
