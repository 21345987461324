import { addressObjectToString, timestampToDateCalendar } from "config/helpers";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import {
	EMPLOYMENT_TYPE,
	PAYMENTS_TYPE,
	PAYMENTS_TIME,
	SEARCH_ADDRESS,
	COMPANY_ADDRESS
} from "config";
import _find from "lodash/find";
import _get from "lodash/get";

export const formatAddress = value => {
	return `${_get(value, "street", "") && _get(value, "street") + " "}${_get(
		value,
		"number",
		""
	) && _get(value, "number", "") + " "}${_get(value, "box", "") &&
		_get(value, "box", "") + " "}${_get(value, "zip", "") &&
		_get(value, "zip", "") + " "}${_get(value, "city", "") &&
		_get(value, "city", "") + " "}${_get(value, "country", "")}`;
};

export function formatAddressesList(list) {
	if (!list) return [];
	const listFormatted = list.map(address => {
		const addressString = addressObjectToString(address);

		return {
			label: addressString,
			value: JSON.stringify(address)
		};
	});

	return listFormatted;
}

export const getDefaultValues = ({ offerData, jobData, candidate }) => {
	let custom_address = {};
	const contract_type = _find(EMPLOYMENT_TYPE, [
		"value",
		offerData?.contract_type || jobData?.employment_type
	]);
	const salary_type = _find(PAYMENTS_TYPE, [
		"value",
		offerData?.["payment_type"] || jobData?.["payment_type"]
	]);

	const payment_periode = _find(PAYMENTS_TIME, [
		"value",
		offerData?.["payment_time"] || jobData?.["payment_time"]
	]);

	if (
		offerData?.location_type === SEARCH_ADDRESS ||
		offerData?.location_type === COMPANY_ADDRESS
	)
		custom_address = {
			street: offerData.street,
			number: offerData.number,
			box: offerData.box,
			city: offerData.city,
			zip: offerData.zip,
			country: offerData.country,
			longitude: offerData.longitude,
			latitude: offerData.latitude,
			iso_country: offerData.iso_country,
			viewport: offerData.viewport
		};

	return {
		contract_type: contract_type?.value || "",
		salary_type: salary_type?.value || "",
		payment_periode: payment_periode?.value || "",
		currency: {
			value: offerData?.["currency"] || jobData?.["currency"] || "EUR",
			symbol: offerData?.["symbol"] || jobData?.["symbol"] || "EUR"
		},
		amount: offerData?.amount * 100 || jobData?.salary_range_end,
		extra_benefits:
			offerData?.["other_benefits"] || jobData?.["other_benefits"],
		location_type: offerData?.location_type || COMPANY_ADDRESS,
		start_date: offerData?.start_date
			? timestampToDateCalendar(offerData.start_date)
			: undefined,
		custom_address,
		files: [],
		...EMPTY_EMAIL,
		to: candidate ? [candidate] : []
	};
};
