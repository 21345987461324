import React, {
	useEffect,
	useLayoutEffect,
	useMemo,
	useRef,
	useState
} from "react";
import MessagingToolEmailView from "common/MessagingToolEmailView/MessagingToolEmailView";
import styles from "./messaging-tool-email-details.module.scss";
import { Button } from "common/Button";
import { ReactComponent as CornerIcon } from "static/icons/corner-down-left.svg";
import useGetEmailDetails from "./api/useGetEmailDetails";
import { generateId, historyPush } from "config/helpers";
import get from "lodash/get";
import { useGetUser } from "hooks/useGetUser";
import MessagingToolEmailDetailsNewEmail from "./components/MessagingToolEmailDetailsNewEmail/MessagingToolEmailDetailsNewEmail";
import useReplyToEmail from "./api/useRelpyToEmail";
import useForwardEmail from "./api/useForwardEmail";
import { getMessagingToolRoute } from "modules/MessagingTool/MessagingTool";
import { SENT, DRAFT, DELETED } from "config";
import { ACTIONS } from "common/MessagingToolEmailView/utils/constants";
import { MESSAGING_TOOL_LINKS } from "modules/MessagingTool/utils/constant";
import { formatDateAndTime } from "common/MessagingToolEmailsList/utils/helpers";

const MessagingToolEmailDetails = ({ emailDetailId, hash }) => {
	const conversationRef = useRef();
	const user = useGetUser();
	const [replyOrForward, setReplyOrForward] = useState(null);
	const [draftEmail, setDraftEmail] = useState(null);
	const [isEditorSmall, setIsEditorSmall] = useState(true);
	const { data, isLoading } = useGetEmailDetails(
		{
			id: emailDetailId,
			is_trash_folder: hash === MESSAGING_TOOL_LINKS.trash.slug
		},
		{
			onError: () => {
				historyPush(getMessagingToolRoute(hash));
			}
		}
	);
	const [replyToEmail] = useReplyToEmail();
	const [forwardEmail] = useForwardEmail();
	const emailId = useMemo(() => (draftEmail ? draftEmail?._id : generateId()), [
		draftEmail
	]);

	useLayoutEffect(() => {
		if (replyOrForward || !isEditorSmall) {
			const divElement = conversationRef?.current;

			if (divElement) divElement.scrollTop = divElement.scrollHeight;
		}
	}, [replyOrForward, isEditorSmall]);

	useEffect(() => {
		if (data) {
			const draft = data.replies.find(item => item.status === DRAFT);
			if (draft) {
				setReplyOrForward({ action: ACTIONS.REPLY, emailId });
				setDraftEmail(draft);
			}
		}
	}, [data, emailId]);

	const onFocusEditorBody = () => {
		setIsEditorSmall(false);
	};

	const getDate = email => {
		const date = {
			[MESSAGING_TOOL_LINKS.schedule.slug]: email?.scheduled_at,
			[MESSAGING_TOOL_LINKS.trash.slug]: email?.deleted_at
		};
		return (
			date[hash] || email?.reply_date || email?.sent_at || email?.created_at
		);
	};

	return (
		<MessagingToolEmailView>
			<MessagingToolEmailView.Header
				emailData={data}
				isLoading={isLoading}
				subject={data?.email_subject || data?.subject}
				onBack={() => historyPush(getMessagingToolRoute(hash))}
				onClickReply={({ action, emailId }) =>
					setReplyOrForward({ action: action || ACTIONS.REPLY, emailId })
				}
				onClickForward={() =>
					setReplyOrForward({ action: ACTIONS.FORWARD, emailId: data?._id })
				}
				hash={hash}
			/>
			<div className={styles.conversation} ref={conversationRef}>
				<MessagingToolEmailView.Message
					messageId={data?.message_id}
					emailData={data}
					onBack={() => historyPush(getMessagingToolRoute(hash))}
					onClickReply={({ emailId }) =>
						setReplyOrForward({ action: ACTIONS.REPLY, emailId })
					}
					onClickForward={({ emailId }) =>
						setReplyOrForward({ action: ACTIONS.FORWARD, emailId })
					}
					isLoading={isLoading}
					fromName={
						get(data, "from[0].email") === user.email
							? "Me"
							: get(data, "from[0].name")
					}
					toName={get(data, "to", [])
						.map(item => (item.email === user.email ? "Me" : item.name))
						.join(", ")}
					content={data?.email_content || data?.content || ""}
					createdAt={formatDateAndTime(getDate(data), "DD/MM/YYYY, HH:mm")}
					to={get(data, "to", [])
						.map(item => item.email)
						.join(", ")}
					from={get(data, "from", [])
						.map(item => item.email)
						.join(", ")}
					bcc={get(data, "bcc", [])
						.map(item => item.email)
						.join(", ")}
					cc={get(data, "cc", [])
						.map(item => item.email)
						.join(", ")}
					attachments={get(data, "attachments", [])}
				/>
				{get(data, "replies", []).map(
					reply =>
						((hash === MESSAGING_TOOL_LINKS.trash.slug &&
							reply.status === DELETED) ||
							reply.status === SENT) && (
							<MessagingToolEmailView.Message
								emailData={reply}
								messageId={reply?.message_id}
								onBack={() => historyPush(getMessagingToolRoute(hash))}
								onClickForward={({ emailId }) =>
									setReplyOrForward({ action: ACTIONS.FORWARD, emailId })
								}
								onClickReply={({ emailId }) =>
									setReplyOrForward({ action: ACTIONS.REPLY, emailId })
								}
								key={reply._id}
								isLoading={isLoading}
								fromName={
									get(reply, "from[0].email") === user.email
										? "Me"
										: get(reply, "from[0].name")
								}
								toName={get(reply, "to", [])
									.map(item => (item.email === user.email ? "Me" : item.name))
									.join(", ")}
								content={reply?.email_content || reply?.content || ""}
								createdAt={formatDateAndTime(
									getDate(reply),
									"DD/MM/YYYY, HH:mm"
								)}
								to={get(reply, "to", [])
									.map(item => item.email)
									.join(", ")}
								from={get(reply, "from", [])
									.map(item => item.email)
									.join(", ")}
								bcc={get(reply, "bcc", [])
									.map(item => item.email)
									.join(", ")}
								cc={get(reply, "cc", [])
									.map(item => item.email)
									.join(", ")}
								attachments={get(reply, "attachments", [])}
							/>
						)
				)}
				{replyOrForward ? (
					<div className={styles.message}>
						<MessagingToolEmailDetailsNewEmail
							emailId={emailId}
							email={draftEmail ? draftEmail : data}
							isDraft={!!draftEmail}
							replyOrForward={replyOrForward}
							onClose={() => {
								setReplyOrForward(null);
							}}
							draftEmail={
								[ACTIONS.REPLY, ACTIONS.REPLY_TO_ALL].includes(
									replyOrForward.action
								)
									? replyToEmail
									: forwardEmail
							}
							isEditorSmall={isEditorSmall}
							onFocusEditorBody={onFocusEditorBody}
							hash={hash}
						/>
					</div>
				) : (
					<div className={styles.footer}>
						<Button
							variant="outlined"
							className={styles.cta}
							text="Reply"
							icon={<CornerIcon className={styles.replyIcon} />}
							onClick={() => {
								setDraftEmail(null);
								setReplyOrForward({
									action: ACTIONS.REPLY,
									emailId: data?.message_id
								});
							}}
						/>
						<Button
							variant="outlined"
							className={styles.cta}
							text="Forward"
							icon={<CornerIcon className={styles.forwardIcon} />}
							onClick={() => {
								setDraftEmail(null);
								setReplyOrForward({
									action: ACTIONS.FORWARD,
									emailId: data?._id
								});
							}}
						/>
					</div>
				)}
			</div>
		</MessagingToolEmailView>
	);
};

export default MessagingToolEmailDetails;
