import React, { useEffect, useRef, useState } from "react";
import styles from "./messaging-tool-header.module.scss";
import { SearchInput } from "common/SearchInput";
import { Button } from "common/Button";
import { ReactComponent as EditIcon } from "static/icons/edit-04.svg";
import { ReactComponent as FilterIcon } from "static/icons/filter.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { ReactComponent as HourGlassIcon } from "static/icons/hourglass-02.svg";
import { Link, withRouter } from "react-router";
import MessaginToolSearchDropdown from "../MessaginToolSearchDropdown/MessaginToolSearchDropdown";
import MessagingToolFilterDropdown from "../MessagingToolFilterDropdown/MessagingToolFilterDropdown";
import useOnClickOutside from "hooks/useOnClickOutside";
import useNotesStore from "modules/notes/notes.store";
import { generateId, historyReplace } from "config/helpers";
import { EMPTY_EMAIL } from "common/MessagingToolNewEmail/utils/constant";
import { NEW_MAIL } from "modules/notes/constants";
import useGetUserEmail from "common/MessagingToolNewEmail/api/useGetUserEmail";
import useGetEmailsList from "common/MessagingToolEmailsList/hooks/useGetEmailsList";
import {
	PARTICIPANTS_LIMIT,
	SYNC_FAILED,
	SYNC_FINISHED,
	SYNC_IN_PROGRESS,
	USERS_AUTOCOMPLETE
} from "modules/MessagingTool/utils/constant";
import useAdvancedFilter from "common/MessagingToolEmailsList/hooks/useAdvancedFilter";
import debounce from "lodash/debounce";
import useCloseSyncEmailBanner from "modules/MessagingTool/api/useCloseSyncEmailBanner";
import { useGetUser } from "hooks/useGetUser";
import { useDispatch } from "react-redux";
import { getMyProfile } from "modules/user/actions/authActions";
import { getMessagingToolRoute } from "modules/MessagingTool/MessagingTool";
import useGetAuthenticationDetails from "modules/company/components/MyProfile/api/useGetAuthenticationDetails";
import { get } from "lodash";
import cx from "classnames";
import { filterIsEmpty } from "modules/MessagingTool/utils/helpers";
import useOnboardingStore from "modules/user-onboarding/hooks/useOnboardingStore";
import { TOUR_MAILBOX } from "config/onboarding";
import { useMailboxTour } from "modules/user-onboarding/workflows/mailbox";
import {
	formatFilterToQuery,
	getFilterObjectFromQuery
} from "../../utils/helpers";

const MessagingToolHeader = ({ params: { hash, emailId } }) => {
	const searchInputRef = useRef();
	const user = useGetUser();
	const dispatch = useDispatch();
	const [openFilter, setOpenFilter] = useState(false);
	const [syncClosed, setSyncClosed] = useState(false);
	const [showBanner, setShowBanner] = useState(true);
	const [query, setQuery] = useState("");
	const [openSearchDropDown, setOpenSearchDropDown] = useState(false);
	const { filters, setFilters } = useAdvancedFilter();
	const [mutate] = useCloseSyncEmailBanner();
	const { data, isLoading } = useGetUserEmail(
		{
			filter: USERS_AUTOCOMPLETE,
			limit: PARTICIPANTS_LIMIT,
			keyword: filters?.words || ""
		},
		{
			enabled: Boolean(filters?.words)
		}
	);
	const { data: authenticationDetails } = useGetAuthenticationDetails();
	const { data: emails, isLoading: isEmailsLoading } = useGetEmailsList(
		{
			folder: hash.toUpperCase(),
			search: filters?.words || "",
			fields: []
		},
		{
			enabled: !filterIsEmpty(filters)
		}
	);
	const searchInputContainerRef = useRef();

	const { setActiveNote, addNote: addEmail } = useNotesStore();

	useEffect(() => {
		setSyncClosed(user.sync_email_banner_closed);
		if (searchInputRef.current) {
			setFilters({
				from: filters?.from || [],
				to: filters?.to || [],
				words: filters?.words,
				subject: filters?.subject,
				has_attachments: Boolean(filters?.has_attachments)
			});
			searchInputRef.current.value = filters?.words || "";
		}
	}, []);

	const onAddNewEmail = () => {
		const id = generateId();
		setActiveNote(id);
		addEmail({
			id,
			type: NEW_MAIL,
			...EMPTY_EMAIL
		});
	};

	useOnClickOutside(searchInputContainerRef, () => {
		setOpenSearchDropDown(false);
		setOpenFilter(false);
	});

	const setFilterFields = words => {
		setFilters(getFilterObjectFromQuery(words));
	};

	const debouncedOnSearch = debounce(value => {
		setFilterFields(value);
		if (!openSearchDropDown) setOpenSearchDropDown(true);
	}, 500);

	const onSearch = e => {
		debouncedOnSearch(e.target.value);
		setQuery(e.target.value);
		if (openFilter) setOpenFilter(false);
	};

	const onOpenFilter = () => {
		setOpenFilter(prev => !prev);
		setOpenSearchDropDown(false);
	};

	const onCloseFilter = () => {
		setOpenFilter(false);
	};

	const onSelect = user => {
		searchInputRef.current.value = user;
		setFilterFields(user);
		setOpenSearchDropDown(false);
	};

	const onClearFilter = () => {
		setQuery("");
		setFilters(null);
		if (openSearchDropDown) setOpenSearchDropDown(false);
		if (openFilter) setOpenFilter(false);

		if (!emailId) historyReplace(getMessagingToolRoute(hash));
	};

	const onSubmitFilter = data => {
		setQuery(formatFilterToQuery(data));
	};

	const closeBanner = () => {
		mutate(
			{
				type: "user_settings_options",
				user_options: {
					sync_email_banner_closed: true
				}
			},
			{
				onSuccess: () => {
					dispatch(getMyProfile()).then(data => {
						setSyncClosed(data.sync_email_banner_closed);
					});
				}
			}
		);
	};

	const { workflowId } = useOnboardingStore();
	useMailboxTour({
		onAddNewEmail
	});

	const renderBanner = status => {
		switch (status) {
			case SYNC_IN_PROGRESS:
				return (
					showBanner && (
						<div className={cx(styles.banner, styles.inProgress)}>
							<HourGlassIcon />
							Your email is currently syncing. This may take a few moments...
							<button
								className={styles.bannerCloseIcon}
								onClick={() => setShowBanner(false)}
							>
								<CloseIcon />
							</button>
						</div>
					)
				);
			case SYNC_FAILED:
				return (
					showBanner && (
						<div className={cx(styles.banner, styles.failed)}>
							⚠️ Error: Unable to sync email. Please retry.
							<Link to="/settings/myprofile" className={styles.syncButton}>
								Sync My Email
							</Link>
							<button
								className={styles.bannerCloseIcon}
								onClick={() => setShowBanner(false)}
							>
								<CloseIcon />
							</button>
						</div>
					)
				);
			default:
				return (
					!syncClosed &&
					![SYNC_FAILED, SYNC_IN_PROGRESS, SYNC_FINISHED].includes(status) && (
						<div className={styles.banner}>
							🔄 Sync your Gmail or Outlook to get all your messages in one
							inbox
							<Link
								to="/settings/myprofile"
								className={styles.syncButton}
								data-onboarding-step={`${TOUR_MAILBOX}-1`}
							>
								Sync My Email
							</Link>
							<button className={styles.bannerCloseIcon} onClick={closeBanner}>
								<CloseIcon />
							</button>
						</div>
					)
				);
		}
	};

	const searchDropdownOpened =
		filters?.words?.length !== 0 &&
		(data?.participants?.length !== 0 || emails?.data?.length !== 0) &&
		openSearchDropDown;

	return (
		<header className={styles.header}>
			{renderBanner(
				workflowId
					? null
					: get(authenticationDetails, "authentication_details.sync_status")
			)}
			<div className={styles.section}>
				<h3 className={styles.headerTitle}>Mailbox</h3>
				<div
					ref={searchInputContainerRef}
					className={styles.searchInputContainer}
				>
					<SearchInput
						ref={searchInputRef}
						inputClassName={cx(
							styles.searchInput,
							cx({
								[styles.withDropdownOpen]: searchDropdownOpened || openFilter
							})
						)}
						placeholder="Search in mail"
						iconPosition="left"
						searchIconClassName={styles.searchIconClassName}
						value={query}
						icon={
							<div className={styles.searchIcons}>
								{searchInputRef?.current?.value && (
									<CloseIcon
										className={styles.icon}
										role="button"
										onClick={onClearFilter}
									/>
								)}
								<FilterIcon
									className={styles.icon}
									role="button"
									onClick={onOpenFilter}
								/>
							</div>
						}
						onChange={onSearch}
					/>
					{searchDropdownOpened && (
						<MessaginToolSearchDropdown
							participants={data?.participants}
							emails={emails?.data}
							isLoading={isLoading || isEmailsLoading}
							onSelect={onSelect}
							hash={hash}
							onClose={() => setOpenSearchDropDown(false)}
						/>
					)}
					{openFilter && (
						<MessagingToolFilterDropdown
							onClearFilter={onClearFilter}
							onClose={onCloseFilter}
							hash={hash}
							onSubmit={onSubmitFilter}
						/>
					)}
				</div>
				<Button
					data-onboarding-step={`${TOUR_MAILBOX}-3`}
					text="New Mail"
					icon={<EditIcon className={styles.editIcon} />}
					rootClassName={styles.newMailButton}
					className={styles.button}
					onClick={onAddNewEmail}
				/>
			</div>
		</header>
	);
};

export default withRouter(MessagingToolHeader);
