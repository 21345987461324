import React, { useState } from "react";
import styles from "./vacancy-view-send-offer-drawer.module.scss";
import Drawer from "rc-drawer";
import { ReactComponent as CloseIcon } from "static/icons/close-x.svg";
import { ReactComponent as ArrowRightIcon } from "static/icons/arrow-right-1.svg";
import { Button } from "common/Button";
import cx from "classnames";
import VancancyViewSendOfferDrawerBody from "../VancancyViewSendOfferDrawerBody/VancancyViewSendOfferDrawerBody";
import { FormProvider } from "react-hook-form";
import useVacancyStore from "../../../vacancyStore";
import useProfileView from "modules/candidate/Profile/components/DrawerFullView/useProfileView";
import useProposeOfferForm from "../../hooks/useProposeOfferForm";
import { useSendOffer } from "../../api/useSendOffer";
import { useEditOffer } from "../../api/useEditOffer";
import toaster from "common/Toaster";
import { queryCache } from "react-query";
import { fetchPoke } from "modules/vacancy/actions";
import { useDispatch } from "react-redux";
import { activeProcessesKey } from "modules/candidate/Profile/api/useActiveProcesses";
import get from "lodash/get";
import { SEARCH_ADDRESS, COMPANY_ADDRESS } from "config";
import { dateToTimestamp, renderError } from "config/helpers";
import { useGetUser } from "hooks/useGetUser";
import {
	ADDRESS_PICKED_FIELDS,
	STEP_ONE,
	STEP_TWO
} from "../../utils/constant";
import VacancyViewSendOfferNewMail from "../VacancyViewSendOfferNewMail/VacancyViewSendOfferNewMail";
import pick from "lodash/pick";
import LeaveHook from "common/LeaveHook";
import VacancyViewAddMailModal from "../../../VacancyViewAddMailModal/VacancyViewAddMailModal";
import { useGetCurrenciesList } from "api/useGetCurrenciesList";
import VacancyViewMissingPlaceholderWarning from "../../../VacancyViewMissingPlaceholderWarning/VacancyViewMissingPlaceholderWarning";
import useValidateLocation from "api/useValidateLocation";
import usePokeData from "modules/candidate/Profile/components/DrawerFullView/api/usePokeData";

const VancancyViewSendOfferDrawer = ({
	onClose,
	isOpen,
	offer,
	job,
	isFromVacanciesTable,
	onSuccess,
	profileId,
	poke
}) => {
	const authenticatedUser = useGetUser();
	const [
		validateAddressMutation,
		{ isLoading: isValidateLocationLoading }
	] = useValidateLocation();
	const dispatch = useDispatch();
	const {
		drawerState: {
			application_id,
			offer: applicantOffer,
			candidateEmail,
			profile_id
		},
		setDrawerState
	} = useVacancyStore();
	const { setIsOpen, setApplicationId, isFromCardActions } = useProfileView();
	const { data: currenciesList } = useGetCurrenciesList();
	const [showDiscardWarning, setShowDiscardWarning] = useState(false);
	const [showAddMailModal, setShowAddMailModal] = useState(false);
	const [
		showMissingPlaceholderWarning,
		setShowMissingPlaceholderWarning
	] = useState(false);
	const [currentStep, setCurrentStep] = useState(STEP_ONE);
	const { data: pokeData } = usePokeData({
		id: job ? null : application_id
	});
	const offerData = Object.keys(offer).length
		? offer
		: Object.keys(applicantOffer).length
		? applicantOffer
		: pokeData?.offer;

	const [files, setFiles] = useState(offerData?.files || []);
	const methods = useProposeOfferForm({
		offerData,
		jobData: job ?? pokeData?.job,
		candidate: { email: candidateEmail, _id: profileId }
	});
	const isDirty = Object.keys(methods.formState.dirtyFields).length > 0;
	const isLoading = methods.formState.isValidating;

	const [sendOffer, { isLoading: isSendLoading }] = useSendOffer({
		onSuccess: (_, req) => {
			setDrawerState({ open: false });
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(application_id);
			toaster.success(
				`the offer proposition has successfully been ${
					req.log_event ? "logged" : "sent"
				}!`
			);
			queryCache.invalidateQueries("getDataPipeline");
			onSuccess?.();
			if (poke?._id) return dispatch(fetchPoke({ id: poke._id }));
		},
		onError: renderError
	});

	const [editOffer, { isLoading: isEditLoading }] = useEditOffer({
		onSuccess: () => {
			setDrawerState({ open: false });
			toaster.success("the new offer proposition has successfully been sent!");
			if (!isFromVacanciesTable) {
				setIsOpen(true);
			}
			setApplicationId(application_id);
			queryCache.invalidateQueries("getDataPipeline");
			queryCache.invalidateQueries(activeProcessesKey);
			onSuccess?.();
			if (poke?._id) return dispatch(fetchPoke({ id: poke._id }));
		},
		onError: renderError
	});

	const closeProposeOffer = () => {
		if (isFromCardActions) {
			setDrawerState({ open: false, component: "" });
		} else {
			setDrawerState({ open: false, component: "" });
			setIsOpen(true);
			setApplicationId(application_id);
		}
	};

	const onSubmit = async (values, logEvent) => {
		let address = {};
		if (values.location_type === SEARCH_ADDRESS) {
			address = values.custom_address;
		} else if (values.location_type === COMPANY_ADDRESS) {
			address = JSON.parse(values.company_address.value);
		}

		const symbol = currenciesList?.find(({ code }) => {
			return code == values.currency.value;
		}).symbol;
		const payload = {
			offer_id: offerData?._id ? offerData._id : undefined,
			contract_type: get(values, "contract_type", ""),
			application_id: application_id,
			start_date: dateToTimestamp(
				`${values.start_date.day}-${values.start_date.month}-${values.start_date.year}`
			),
			files,
			location_type: get(values, "location_type"),
			other_benefits: get(values, "extra_benefits", ""),
			amount: parseFloat(get(values, "amount")) / 100,
			payment_time: get(values, "payment_periode", ""),
			payment_type: get(values, "salary_type", ""),
			currency: get(values, "currency.value", ""),
			symbol,
			log_event: logEvent,
			...pick(address, ADDRESS_PICKED_FIELDS),
			...(values.location_type === SEARCH_ADDRESS && {
				viewport:
					Object.keys(values.custom_address.viewport || {}).length > 0
						? values.custom_address.viewport
						: {
								northeast: {
									lat: get(
										values,
										"custom_address.geometry.viewport",
										undefined
									)
										?.getNorthEast?.()
										?.lat?.(),
									lng: get(
										values,
										"custom_address.geometry.viewport",
										undefined
									)
										?.getNorthEast?.()
										?.lng?.()
								},
								southwest: {
									lat: get(
										values,
										"custom_address.geometry.viewport",
										undefined
									)
										?.getSouthWest?.()
										?.lat?.(),
									lng: get(
										values,
										"custom_address.geometry.viewport",
										undefined
									)
										?.getSouthWest?.()
										?.lng?.()
								}
						  }
			}),
			...(!logEvent && {
				to: values.to,
				content: values.content,
				subject: values.subject,
				content_placeholders: values.content_placeholders.map(placeholder => ({
					key: placeholder.value,
					value:
						placeholder.value === "OFFER_LINK" && !placeholder._id
							? profile_id
							: placeholder._id || ""
				}))
			})
		};
		if (Object.keys(offerData || {}).length > 0) return editOffer(payload);
		sendOffer(payload);
	};

	const handleNext = async e => {
		e?.preventDefault();
		const customAddress = methods.getValues("custom_address");
		const locationType = methods.getValues("location_type");
		try {
			if (currentStep === STEP_ONE) {
				const valid = await methods.trigger([
					"contract_type",
					"amount",
					"currency",
					"salary_type",
					"payment_periode",
					"payment_periode",
					"start_date",
					"location_type",
					"custom_address",
					"company_address"
				]);
				if (!valid) return;

				await validateAddress({
					locationType,
					locationValue: {
						...customAddress,
						viewport:
							Object.keys(customAddress.viewport || {}).length > 0
								? customAddress.viewport
								: {
										northeast: {
											lat: customAddress?.geometry?.viewport
												?.getNorthEast?.()
												?.lat?.(),
											lng: customAddress?.geometry?.viewport
												?.getNorthEast?.()
												?.lng?.()
										},
										southwest: {
											lat: customAddress?.geometry?.viewport
												?.getSouthWest?.()
												?.lat?.(),
											lng: customAddress?.geometry?.viewport
												?.getSouthWest?.()
												?.lng?.()
										}
								  }
					}
				});
				if (!methods.watch("to")[0].email) {
					setShowAddMailModal(true);
					return;
				}
				if (valid) setCurrentStep(STEP_TWO);
			}
		} catch (error) {
			methods.setError("custom_address", {
				message: "Please enter a valid address"
			});
		}
	};

	const validateAddress = async ({ locationType, locationValue }) => {
		if (locationType === SEARCH_ADDRESS) {
			return await new Promise((resolve, reject) => {
				validateAddressMutation(
					{ location: locationValue },
					{
						onSuccess: resolve,
						onError: reject
					}
				);
			});
		}
	};

	const onSaveAsLogged = () => {
		methods.handleSubmit(onSubmit)(true);
	};

	const onClickCancel = () => {
		if (isDirty) {
			setShowDiscardWarning(true);
		} else {
			onClose();
		}
	};

	return (
		<Drawer
			open={isOpen}
			width="998px"
			height="100%"
			placement="right"
			style={{ zIndex: 1000, backgroundColor: "transparent" }}
			level={"root"}
			maskClosable={true}
			onClose={onClickCancel}
		>
			<FormProvider {...methods}>
				<form
					className={styles.container}
					onSubmit={methods.handleSubmit(data => onSubmit(data, false))}
				>
					<header className={styles.header}>
						<h3 className={styles.title}>Send Offer</h3>
						<button
							className={styles.closeBtn}
							type="button"
							onClick={onClickCancel}
							disabled={isLoading}
						>
							<CloseIcon />
						</button>
					</header>
					<div className={styles.body}>
						<VancancyViewSendOfferDrawerBody
							files={files}
							setFiles={setFiles}
							currenciesList={currenciesList}
							isVisible={currentStep === STEP_ONE}
						/>
						<VacancyViewSendOfferNewMail
							jobData={job ?? pokeData?.job}
							isVisible={currentStep === STEP_TWO}
						/>
					</div>
					<footer className={styles.footer}>
						<Button
							className={cx(styles.btn, styles.outlined, {
								[styles.borderless]: currentStep === STEP_TWO
							})}
							text="Cancel"
							variant="outlined"
							type="button"
							onClick={onClickCancel}
						/>
						<div className={styles.rightActions}>
							{currentStep === STEP_TWO && (
								<Button
									className={cx(styles.btn, styles.outlined)}
									text="Back"
									variant="outlined"
									type="button"
									onClick={() => setCurrentStep(STEP_ONE)}
								/>
							)}
							{currentStep === STEP_ONE ? (
								<>
									<Button
										onClick={onSaveAsLogged}
										text="Save as Logged"
										className={cx(styles.btn, styles.saveAsLoggedBtn)}
										type="button"
									/>
									<Button
										className={styles.btn}
										text="Send Offer"
										type="button"
										rightIcon={<ArrowRightIcon />}
										onClick={handleNext}
										isLoading={isLoading || isValidateLocationLoading}
									/>
								</>
							) : (
								<Button
									className={styles.btn}
									text="Send Offer"
									type="submit"
									isLoading={isSendLoading || isEditLoading}
								/>
							)}
						</div>
					</footer>
				</form>
				{showMissingPlaceholderWarning && (
					<VacancyViewMissingPlaceholderWarning
						onClose={() => setShowMissingPlaceholderWarning(false)}
						placeholderLabel="offer.link"
					/>
				)}
				<LeaveHook
					dirty={isDirty}
					enforceWarning={showDiscardWarning}
					confirmationModal={{
						onDiscard: onClose,
						onClose: () => setShowDiscardWarning(false),
						isLoading: isLoading,
						disabled: isLoading
					}}
				/>
				{showAddMailModal && (
					<VacancyViewAddMailModal
						logInterviewHandler={onSaveAsLogged}
						closeDrawer={closeProposeOffer}
						profileId={profileId}
						vacancyId={(job ?? pokeData?.job)._id}
						activeCompany={authenticatedUser?.active_company}
						setShowAddMailModal={setShowAddMailModal}
						labelKey="offer"
						onEmailAdded={email => {
							methods.setValue("to", [{ email, _id: profileId }]);
							handleNext();
						}}
					/>
				)}
			</FormProvider>
		</Drawer>
	);
};

export default VancancyViewSendOfferDrawer;
