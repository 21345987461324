import React from "react";
import { Controller } from "react-hook-form";
import InputField from "modules/vonqContract/components/InputFiled";
import styles from "./posting-requirements-form-required-field.module.scss";

const PostingRequirementsFormRequiredField = ({
	field,
	control,
	onFieldChange,
	getFieldMessageError
}) => {
	return (
		<Controller
			name={field.name}
			control={control}
			key={field.name}
			rules={{ required: true }}
			defaultValue=""
			render={({ field: { onChange, value } }) => (
				<InputField
					value={value}
					onChange={e => {
						onChange(e);
						onFieldChange(field.name);
					}}
					type="text"
					label={
						<>
							{field.name}
							<span className={styles.error}>*</span>
						</>
					}
					error={getFieldMessageError(field.name)}
					className={styles.input}
				/>
			)}
		/>
	);
};

export default PostingRequirementsFormRequiredField;
