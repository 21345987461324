import React, { useState } from "react";
import { Letter } from "react-letter";
import root from "react-shadow";
import styles from "./render-email.module.scss";
import { FALLBACK_IMG } from "common/MessagingToolEmailView/utils/constants";
import get from "lodash/get";
import useGetInlineImagesBase64 from "common/MessagingToolEmailView/api/useGetInlineImagesBase64";

export default function RenderEmail({ html, attachments, messageId }) {
	const [processedHtml, setProcessedHtml] = useState(html);
	const grantId = get(attachments, "[0].grant_id", "");
	useGetInlineImagesBase64(
		{
			body: {
				ids: attachments.map(item => item.id),
				grant_id: grantId,
				message_id: messageId,
				as_base64: true
			},
			isBlob: false
		},
		{
			enabled: !!grantId,
			onSuccess: data => {
				let newHtml = html;
				Object.entries(data).forEach(([key, value]) => {
					const cid = attachments.find(item => item.id === key)?.content_id;
					newHtml = newHtml.replaceAll(`src="cid:${cid}"`, `src="${value}"`);
				});
				setProcessedHtml(newHtml);
			}
		}
	);

	return (
		<root.div mode="closed" className={styles.message}>
			<Letter
				html={processedHtml}
				rewriteExternalResources={url => {
					if (url.startsWith("cid:")) return FALLBACK_IMG;
					return url;
				}}
			/>
		</root.div>
	);
}
