import React from "react";
import styles from "./banner.module.scss";
import { ReactComponent as NotificationIcon } from "static/icons/notification-icon-yellow.svg";
import { ReactComponent as CloseIcon } from "static/icons/close-icon-grey.svg";
import cx from "classnames";

const Banner = ({
	children,
	onClose,
	className,
	icon,
	displayCloseIcon = true
}) => {
	return (
		<div className={cx(styles.notificationItem, className)}>
			{icon || <NotificationIcon />}
			<div className={styles.notificationDescription}>{children}</div>
			{displayCloseIcon && (
				<button onClick={onClose} className={styles.closeButtonItem}>
					<CloseIcon />
				</button>
			)}
		</div>
	);
};

export default Banner;
